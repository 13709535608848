import axios from "axios";
import { defineStore } from "pinia";
import { useAppState } from "./AppState";
import { useConversation } from "../Stores/Conversation";

const FULL_MODE = "fullscreen";
const PANEL_MODE = "accordion";
const SPACES = "spaces";
const BOARDS = "boards";
const ITEMS = "items";

const INVITE = {
    ITEM: "item",
    ITEM_GROUP: "item-group",
    BOARD: "board",
    BOARD_GROUP: "board-group",
    SPACE_GROUP: "workspace-group",
    SPACE: "workspace",
};

import { useTimeTrackerStore } from "./TimeTracker";
import Utilities from "../Helpers/utilities";
import { useTeamPanel } from "./TeamPanel";
import { useTeam } from "../Components/Custom/Team/Store/Team";
import { useUserStore } from "./User";
import { useWorkspaceDnd } from "./WorkspaceDragAndDrop";
import Conversation from "../Utilities/Conversation";
import { getChinUsers } from "../Helpers/chin";

export const WORKSPACE_TYPES = [
    {
        type: "workspace",
        title: "Common Workspace",
        subtitle:
            "Create a standard 3-section workspace consistent of Spaces, Boards, and Items in kanban style.",
        icon: "notes",
    },
    {
        type: "workstation",
        title: "Workstation Connect",
        subtitle:
            "Download our free desktop application and collect desktop screenshots and productivity data.",
        icon: "computer",
    },
    {
        type: "external_connect",
        title: "External Connect",
        subtitle:
            "Create a simple link to any other application you use, with a 3-part kanban style workspace.",
        icon: "link",
        disabled: true,
    },
    {
        type: "feedback",
        title: "WordPress Connect",
        subtitle:
            "Add our sales chat, technical support chat, and collaboration features directly to your website.",
        disabled: true,
        icon: "",
    },
];

export const useWorkspace = defineStore("workspace", {
    state: () => {
        return {
            new_group_default_label: "Unnamed Group",
            space_categories: [],
            active_panel: "spaces",
            active_space: null,
            active_board: null,
            active_item: null,
            active_view_mode: PANEL_MODE,
            active_chat_data: null,
            invited_data: null,
            hovered_space: null,
            hovered_board: null,
            hovered_item: null,
            selected_space_for_saving: null,
            create_new_workspace_toggle: false,
            editor_view: false,

            are_space_boards_being_loaded: false,
            are_board_items_being_loaded: false,
            new_space: false,
            new_space_category: null,
            invite_type: null,
            checked_invite_profile_url: null,
            item_panel_state: null,
            document_error_message:
                "Failed to save Document updates, pls save editor content in your local editor",
            expanded_space: false,
            is_editing_item: false,
            is_hover_menu_disabled: false,
            last_updated_item: null,
            is_invitation_in_progress: false,
            chin: {
                item_profile_ids: [],
                board_profile_ids: [],
                space_profile_ids: [],
            },
            chin_selected: {
                item_profile_ids: [],
                board_profile_ids: [],
                space_profile_ids: [],
            },
            previous_active_item: null,
            fetching_item: false,
            new_space_options: false,
            is_scrolling_categories: false,
        };
    },
    getters: {
        is_panel_mode: (state) => state.active_view_mode === PANEL_MODE,
        is_full_mode: (state) => state.active_view_mode === FULL_MODE,
        is_invite_on_item: (state) => state.invite_type === INVITE.ITEM,
        is_invite_on_item_group: (state) =>
            state.invite_type === INVITE.ITEM_GROUP,
        is_invite_on_board: (state) => state.invite_type === INVITE.BOARD,
        is_invite_on_board_group: (state) =>
            state.invite_type === INVITE.BOARD_GROUP,
        is_invite_on_space_group: (state) =>
            state.invite_type === INVITE.SPACE_GROUP,
        is_invite_space: (state) => state.invite_type === INVITE.SPACE,
        is_active_panel_spaces: (state) => state.active_panel === SPACES,
        is_active_panel_boards: (state) => state.active_panel === BOARDS,
        is_active_panel_items: (state) => state.active_panel === ITEMS,
        get_active_panel: (state) => state.active_panel,
        get_selected_space_for_saving: (state) =>
            state.selected_space_for_saving,
        is_extended_space_active: (state) => state.expanded_space,
        space_categories_url: () => `/workspace-categories`,
        spaces_url: () => `/workspaces`,
        board_categories_url: (state) =>
            `/workspaces/${state.active_space.slug}/board-categories`,
        boards_url: (state) => `/workspaces/${state.active_space.slug}/boards`,
        item_categories_url: (state) =>
            `${state.boards_url}/${state.active_board.slug}/item-categories`,
        items_url: (state) =>
            `${state.boards_url}/${state.active_board?.slug}/items`,
        document_url: (state) =>
            `${state.boards_url}/${state.active_board?.slug}/items/${state.active_item?.slug}/document`,
        item_document_url: (state) =>
            `${state.boards_url}/${state.active_board?.slug}/items/${state.active_item?.slug}/document`,
        chin_users: (state) => {
            return getChinUsers({
                profiles: useUserStore().profiles,
                all_profile_ids: state.chin.item_profile_ids,
                selected_profile_ids: state.chin_selected.item_profile_ids,
            });
        },
        chin_workspace_users: (state) => {
            return getChinUsers({
                profiles: useUserStore().profiles,
                all_profile_ids: state.chin.space_profile_ids,
                selected_profile_ids: state.chin_selected.space_profile_ids,
            });
        },
        chin_board_users: (state) => {
            return getChinUsers({
                profiles: useUserStore().profiles,
                all_profile_ids: state.chin.board_profile_ids,
                selected_profile_ids: state.chin_selected.board_profile_ids,
            });
        },
    },
    actions: {
        setCurrentBreadcrumb(breadcrumbs) {
            this.breadcrumbs = breadcrumbs;
        },
        setPanelMode() {
            this.active_view_mode = PANEL_MODE;
        },
        setFullMode() {
            this.active_view_mode = FULL_MODE;
        },
        setSelectedSpaceForSaving(space) {
            this.selected_space_for_saving = space;
        },
        toggleNewSpace(category = null) {
            this.expanded_space = false;
            this.new_space = !this.new_space;
            this.new_space_category = category;
        },
        setActivePanel(newValue) {
            this.active_panel = newValue;
            useAppState().updateActivePanel(newValue);
        },
        openSpacesPanel() {
            this.setActivePanel(SPACES);
        },
        openBoardsPanel() {
            this.setActivePanel(BOARDS);
        },
        openItemsPanel() {
            this.setActivePanel(ITEMS);
        },
        spaceBoardsLoadingStarted() {
            this.are_space_boards_being_loaded = 1;
        },
        spaceBoardsLoadingCompleted() {
            this.are_space_boards_being_loaded = 0;
        },
        boardItemsLoadingStarted() {
            this.are_board_items_being_loaded = 1;
        },
        boardItemsLoadingCompleted() {
            this.are_board_items_being_loaded = 0;
        },
        dataLoadingCompleted() {
            this.is_data_being_loaded = 0;
        },
        getOwnerIdForSpace(space_or_space_id) {
            if (!space_or_space_id) return null;

            if (typeof space_or_space_id == "object")
                space_or_space_id = space_or_space_id.id;
            const space = this.getSpaceById(space_or_space_id);
            return space?.owner_id;
        },
        getSpaceOwnerId(space = null) {
            return (space ?? this.active_space)?.owner_id;
        },
        isItemOwner(item) {
            return (
                item?.user_profile_id ==
                useUserStore().getCurrentUserProfileId()
            );
        },
        setActiveSpace(newValue) {
            // this.active_space = newValue
            this.active_chat_data = newValue;
        },
        resetItemsPanel() {
            this.setActiveItem(null);
            this.setEditorView(false);
        },
        resetEditorIfOpenedOnPrevioulySelectedBoardItem(newlySelectedBoard) {
            if (
                this.active_board &&
                this.active_board.id == newlySelectedBoard.id
            )
                return;

            this.resetItemsPanel();
        },
        setActiveBoard(board) {
            if (!board?.id) {
                this.active_board = {}
                return console.log("you can't open a Board not created yet");
            }

            if (board.id == this.active_board?.id) {
                // board is already selected.
                return;
            }

            this.boardItemsLoadingStarted();
            this.resetEditorIfOpenedOnPrevioulySelectedBoardItem(board);
            this.active_board = board;
            this.active_chat_data = board;
            this.loadItems(board);

            const app_state_store = useAppState();
            app_state_store.updateBoardId(board.id);
        },
        setActiveChatData(data) {
            if (!data?.id) return (this.active_chat_data = {});

            if (!data.document) this.setItemDocument(data);

            this.active_chat_data = data;
        },
        setActiveChatDataEmpty() {
            this.active_chat_data = null;
        },
        setActiveItem(item, is_set_selected_item = true) {
            if (!item?.id) return (this.active_item = {});

            if (
                !useWorkspaceDnd().selected_items.length &&
                is_set_selected_item
            )
                useWorkspaceDnd().clearSelectedItems(item);

            this.previous_active_item = item;
            this.active_item = item;
            this.active_chat_data = item;
            this.setItemDocument(item);
            useAppState().updateSelectedItemId(item.id);
        },
        setMissingDocumentOnItemsForBoard(board) {
            board.item_categories.forEach((ic) => {
                ic.items?.forEach((item) => {
                    useWorkspace().setItemDocument(item);
                });
            });
        },
        setItemDocument(item) {
            if (item.document?.id) return;

            if (!item.document) item.document = {};

            item.document.documentable_type = "item";
            item.document.documentable_id = item.id;
            item.document.content = "";
            item.document.title = item.name;

            return item;
        },
        setActiveItemEmpty() {
            this.active_item = null;
        },
        setHoveredSpace(newValue) {
            this.hovered_space = newValue;
        },
        setHoveredBoard(newValue) {
            this.hovered_board = newValue;
        },
        setHoveredItem(newValue) {
            this.hovered_item = newValue;
        },
        setSpaces(spaces) {
            this.space_categories = spaces;
            this.hover_spaces = spaces;
        },
        setInviteType(type) {
            this.invite_type = type;
        },
        setCheckedInviteProfile_url(url) {
            this.checked_invite_profile_url = url;
        },
        areWorkspaceBoardsLoaded() {
            return (
                this.active_space.has_boards_loaded ||
                this.active_space?.board_categories?.length
            );
        },
        resetActiveBoard() {
            this.active_board = {};
            this.resetItemsPanel();
        },
        setActiveSpaceBoardItemOnBreadcrumb(space, board, item, type) {
            this.setActiveWorkspace(space);
            this.setActiveBoard(board);
            this.setActiveItem(item);

            if (type == "Space") {
                this.openBoardsPanel();
            } else if (type == "Board") {
                this.openBoardsPanel();
            }
        },
        setActiveWorkspace(space) {
            if (!space?.id || space.id == this.active_space?.id)
                return console.log("fake Space or currently selected board");

            this.spaceBoardsLoadingStarted();
            this.active_space = space;
            this.active_chat_data = space;
            this.resetActiveBoard();
            this.loadBoards(space);

            const app_state_store = useAppState();
            app_state_store.updateWorkspaceId(space.id);
        },
        async createSpaceCategory(name) {
            this.space_categories.splice(0, 0, { name });
            const { data } = await axios.post(this.space_categories_url, {
                name,
            });
            this.space_categories.splice(0, 1, data);
        },
        loadBoards(space) {
            if (space.has_boards_loaded || space.board_categories?.length)
                return this.spaceBoardsLoadingCompleted();

            this.fetchBoards(space);
        },

        async fetchBoards(space) {
            const { data } = await axios.get(
                `/workspaces/${space.slug}/boards`,
            );
            space.board_categories = data;
            space.has_boards_loaded = true;
            this.spaceBoardsLoadingCompleted();
        },
        areBoardItemsLoaded() {
            return (
                this.active_board.has_items_loaded ||
                this.active_board?.item_categories?.length
            );
        },
        loadItems(board, space = null) {
            if (board.has_items_loaded || board.item_categories?.length)
                return this.boardItemsLoadingCompleted();

            space = space ?? this.active_space;
            this.fetchItems(board, space);
        },
        async fetchItems(board, space) {
            const { data } = await axios.get(
                `/workspaces/${space.slug}/boards/${board.slug}/items`,
            );
            // const { data } = await axios.get(`${this.boards_url}/${board.slug}/items`)
            board.item_categories = data;
            board.has_items_loaded = true;

            if (this.item_panel_state?.is_doc_view)
                this.setMissingDocumentOnItemsForBoard(board);

            this.boardItemsLoadingCompleted();
        },
        setEditorView(value) {
            this.editor_view = value;
        },

        openEditor(item_id = null) {
            item_id = item_id ?? this.active_item?.id;

            if (!item_id) return;

            this.setEditorView(true);
            const app_state_store = useAppState();
            app_state_store.updateEditor(item_id);
        },

        closeEditor(value = true) {
            //this.setActiveItem(null);
            this.setEditorView(false);

            this.active_item && (this.active_item.document = null)

            const app_state_store = useAppState();
            app_state_store.updateEditor(null);
            if (value) useWorkspaceDnd().skipMultiSelect();
        },
        loadLastOpenedSpaceAndBoard(data) {
            const { workspace, board } = data;

            this.replaceSpace(workspace);
            this.replaceBoard(board);
        },
        restoreActivePanel(active_panel) {
            if (![SPACES, BOARDS, ITEMS].includes(active_panel)) return;

            this.setActivePanel(active_panel);
        },
        removeItemById(item_id) {
            const destination_item = this.getSpaceBoardForItem(item_id);
            const category_items = destination_item?.item_category?.items;

            if (!category_items) return;

            const item_index = category_items.findIndex(
                (item) => item.id == item_id,
            );
            if (item_index == -1) return;

            category_items.splice(item_index, 1);
        },
        removeSpaceGroupById(space_group_id) {
            const space_group_index = this.space_categories.findIndex(
                (space_category) => space_category.id == space_group_id,
            );
            if (space_group_index === -1) {
                return;
            }

            this.space_categories.splice(space_group_index, 1);
        },
        removeSpaceById(space_id) {
            const space_group = this.getSpaceGroupBySpaceId(space_id);
            if (!space_group) {
                return;
            }

            const space_index = space_group.workspaces.findIndex(
                (space) => space.id == space_id,
            );
            if (space_index === -1) {
                return;
            }

            space_group.workspaces.splice(space_index, 1);
        },
        removeBoardGroupById(board_group_id) {
            const workspace = this.getSpaceByBoardGroupId(board_group_id);
            if (!workspace) {
                return;
            }

            const board_group_index = workspace.board_categories.findIndex(
                (board_group) => board_group.id == board_group_id,
            );
            if (board_group_index === -1) {
                return;
            }

            workspace.board_categories.splice(board_group_index, 1);
        },
        removeBoardById(board_id) {
            const board_group = this.getBoardGroupByBoardId(board_id);
            if (!board_group) {
                return;
            }

            const board_index = board_group.boards.findIndex(
                (board) => board.id == board_id,
            );
            if (board_index === -1) {
                return;
            }

            board_group.boards.splice(board_index, 1);
        },
        removeItemGroupById(board_id) {
            const board = this.getBoardByItemGroupId(board_id);
            if (!board) {
                return;
            }

            const item_group_index = board.item_categories.findIndex(
                (board) => board.id == board_id,
            );
            if (item_group_index === -1) {
                return;
            }

            board.item_categories.splice(item_group_index, 1);
        },
        findItemInActiveBoard(item_id) {
            return this.findItemInBoard(item_id, this.active_board);
        },
        findItemInBoard(item_id, board) {
            if (!board || !item_id) return null;
            let item_found = null;
            board?.item_categories?.find((ic) =>
                ic.items?.find((item) =>
                    item.id == item_id ? (item_found = item) : false,
                ),
            );
            return item_found;
        },
        getSpaceById(space_id) {
            if (!space_id) return null;
            let space_found = null;
            this.space_categories.find((sc) =>
                sc.workspaces?.find(
                    (space) => space.id == space_id && (space_found = space),
                ),
            );
            return space_found;
        },
        getSpaceBoardForItem(item_id) {
            if (!item_id) return console.log("can't search for invalid item");

            let item_found = null,
                board_found = null,
                workspace_found = null,
                item_category = null;

            this.space_categories.find((sc) => {
                return (workspace_found = sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find(
                        (bc) =>
                            (board_found = bc.boards?.find(
                                (board) =>
                                    (item_category =
                                        board.item_categories?.find((ic) =>
                                            ic.items?.find((item) =>
                                                item.id == item_id
                                                    ? (item_found = item)
                                                    : false,
                                            ),
                                        )),
                            )),
                    );
                }));
            });

            return {
                item: item_found,
                board: board_found,
                space: workspace_found,
                item_category,
            };
        },
        async openItemWithWorkspaceAndBoardIfNotOpened(
            item_id = null,
            is_scroll_to = false,
            is_toggle_loader = true,
        ) {
            const app_state_store = useAppState();

            if (!item_id) return console.log("can't search for invalid item");

            if (is_toggle_loader) {
                app_state_store.togglePageLoader(true);
            }

            const { space, board, item } =
                await this.getOrLoadSpaceAndBoardForItemId(item_id);

            if (!item || !board || !space) {
                this.setIsFetchingItem(false);
                if (is_toggle_loader) {
                    app_state_store.togglePageLoader(false);
                }
                return;
            }

            this.setActiveWorkspace(space);
            this.setActiveBoard(board);
            this.setActiveItem(item);

            useWorkspaceDnd().clearSelectedItems(item);
            if (is_scroll_to) {
                timeOutHelper(() => {
                    window.scrollTo(
                        "category-group-id-" + item.item_category_id,
                    );
                }, 1);
            }

            this.openItemsPanel();

            this.setIsFetchingItem(false);
            if (is_toggle_loader) {
                app_state_store.togglePageLoader(false);
            }
        },

        findItemInWorkspaces(item_id = null, set_space_board = false) {
            let itemFound = null,
                boardFound = null,
                workspaceFound = null;

            this.space_categories.find((sc) => {
                return (workspaceFound = sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find(
                        (bc) =>
                            (boardFound = bc.boards?.find((board) =>
                                board.item_categories?.find((ic) =>
                                    ic.items?.find((item) =>
                                        item.id == item_id
                                            ? (itemFound = item)
                                            : false,
                                    ),
                                ),
                            )),
                    );
                }));
            });
            /* if(!itemFound)
                return;

            if(workspaceFound?.id !== this.active_space?.id)
                this.setActiveSpace(workspaceFound)

            if (boardFound?.id !== this.active_board?.id)
                this.setActiveBoard(boardFound)
 */

            if (set_space_board && itemFound) {
                this.setActiveWorkspace(workspaceFound);
                this.setActiveBoard(boardFound);
            }

            return itemFound;
        },

        getBoardByItemGroupId(item_group_id = null) {
            let boardFound = null,
                workspaceFound = null;

            this.space_categories.find((sc) => {
                return (workspaceFound = sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find(
                        (bc) =>
                            (boardFound = bc.boards?.find((board) =>
                                board.item_categories?.find(
                                    (ic) => ic.id == item_group_id,
                                ),
                            )),
                    );
                }));
            });

            return boardFound;
        },
        getItemGroupByItemId(item_id = null) {
            let item_group_found = null;

            this.space_categories.find((sc) => {
                return sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find((bc) =>
                        bc.boards?.find((board) => {
                            return (item_group_found =
                                board.item_categories?.find((item_group) =>
                                    item_group?.items.find(
                                        (item) => item.id == item_id,
                                    ),
                                ));
                        }),
                    );
                });
            });

            return item_group_found;
        },
        getBoardByBoardId(board_id = null) {
            let boardFound = null,
                workspaceFound = null;

            this.space_categories.find((sc) => {
                return (workspaceFound = sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find((bc) => {
                        return (boardFound = bc.boards?.find(
                            (board) => board.id == board_id,
                        ));
                    });
                }));
            });

            return boardFound;
        },
        getSpaceByBoardId(board_id = null) {
            let workspaceFound = null;

            this.space_categories.find((sc) => {
                return (workspaceFound = sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find((bc) =>
                        bc.boards?.find((board) => board.id == board_id),
                    );
                }));
            });
            return workspaceFound;
        },
        getBoardGroupByBoardId(board_id = null) {
            let boardGroupFound = null;

            this.space_categories.find((sc) => {
                return sc.workspaces?.find((workspace) => {
                    return (boardGroupFound = workspace.board_categories?.find(
                        (bc) =>
                            bc.boards?.find((board) => board.id == board_id),
                    ));
                });
            });
            return boardGroupFound;
        },
        getBoardGroupByBoardGroupId(board_group_id = null) {
            let boardGroupFound = null;

            this.space_categories.find((sc) => {
                return sc.workspaces?.find((workspace) => {
                    return (boardGroupFound = workspace.board_categories?.find(
                        (bc) => bc.id == board_group_id,
                    ));
                });
            });
            return boardGroupFound;
        },
        getSpaceByBoardGroupId(board_group_id = null) {
            let workspace_found = null;

            this.space_categories.find((sc) => {
                return (workspace_found = sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find(
                        (bc) => bc.id == board_group_id,
                    );
                }));
            });
            return workspace_found;
        },
        getSpaceGroupBySpaceId(space_id = null) {
            let workspace_found = null;

            workspace_found = this.space_categories.find((sc) => {
                return sc.workspaces?.find(
                    (workspace) => workspace.id == space_id,
                );
            });
            return workspace_found;
        },
        getSpaceBySpaceId(space_id = null) {
            let workspace_found = null;
            this.space_categories.find((sc) => {
                return (workspace_found = sc.workspaces?.find(
                    (workspace) => workspace.id == space_id,
                ));
            });
            return workspace_found;
        },
        getSpaceGroupBySpaceGroupId(space_group_id = null) {
            return this.space_categories.find((sc) => sc.id == space_group_id);
        },
        restoreEditorState(document_id, item_id) {
            if (useAppState().is_activity) return;

            if (!document_id && !item_id) return;

            let itemFound = null;

            this.active_board?.item_categories?.find((ic) =>
                ic.items?.find((item) =>
                    item.id == item_id ? (itemFound = item) : false,
                ),
            );

            if (!itemFound)
                return console.log("no item found for opening editor");

            this.setActiveItem(itemFound);
            document_id && this.setEditorView(true);

            if (document_id) {
                this.setEditorView(true);
                this.openEditor(document_id);
            }
        },
        replaceSpace(space) {
            const { category, index } =
                this.getSpaceCategoryAndIndexForSpace(space);

            if (index == -1)
                return console.log(
                    `Workspace ${space?.name} not found in Category`,
                );

            //space_category.workspaces.splice(space_index, 1, space)
            this.active_space = category.workspaces[index];
            this.active_space.has_boards_loaded = true;
            this.active_space.board_categories = space.board_categories;
            this.setActivePanel(BOARDS);
        },
        replaceBoard(board) {
            const { category, index } =
                this.getBoardCategoryAndIndexForBoardInSpace(
                    board,
                    this.active_space,
                );
            if (index == -1)
                return console.log(`Board ${board?.name} Not found to replace`);

            //board_category.boards.splice(board_indedx, 1, board)
            this.active_board = category.boards[index];
            this.active_board.has_items_loaded = true;
            this.active_board.item_categories = board.item_categories;
            this.setActivePanel(ITEMS);
        },
        prepareUrlWithSpaceAndBoardUssingIds(space_id, board_id) {
            space_id = space_id ?? this.active_space.id;
            board_id = board_id ?? this.active_board.id;

            return `/workspaces/${space_id}/boards/${board_id}`;
        },
        prepareUrlWithSpaceAndBoard(space, board) {
            space = space ?? this.active_space;
            board = board ?? this.active_board;

            return `/workspaces/${space.slug}/boards/${board?.slug}`;
        },
        prepareUrlWithSpace(space) {
            space = space ?? this.active_space;
            return `/workspaces/${space.slug}`;
        },
        prepareUrlWithSpaceGroup(space_group) {
            return `/workspace-categories/${space_group.slug}`;
        },
        prepareUrlFoSpaceGroupInvitation(space_group) {
            const url = this.prepareUrlWithSpaceGroup(space_group);
            return `${url}/invite`;
        },
        prepareUrlFoSpaceInvitation(space) {
            const url = this.prepareUrlWithSpace(space);
            return `${url}/invite`;
        },
        prepareUrlForBoardGroupInvitation(board_group) {
            const url = this.prepareUrlWithSpace(this.active_space);
            return `${url}/board-categories/${board_group.id}/invite`;
        },
        prepareUrlForBoardInvitation(board) {
            const url = this.prepareUrlWithSpace(this.active_space);
            return `${url}/boards/${board.id}/invite`;
        },
        prepareUrlForItemGroupInvitation(item_group) {
            const url = this.prepareUrlWithSpaceAndBoard(
                this.active_space,
                this.active_board,
            );
            return `${url}/item-categories/${item_group.id}/invite`;
        },
        prepareUrlForItem(item, item_space = null, item_board = null) {
            item = item ?? this.active_item;

            let item_data = this.getSpaceBoardForItem(item.id);

            if (!item_data?.space || !item_data?.board) {
                console.trace(
                    "Space or Board not found for item",
                    item,
                    item_data?.board,
                    item_data?.space,
                );

                item_data = this.getOrLoadSpaceAndBoardForItemId(item.id);
            }

            const url = this.prepareUrlWithSpaceAndBoard(
                item_data?.space,
                item_data?.board,
            );
            return `${url}/items/${item?.slug}`;
        },
        prepareUrlForItemInvitation(item = null, space = null, board = null) {
            const url = this.prepareUrlForItem(item, space, board);
            return `${url}/invite`;
        },
        prepareUrlForItemConversationInvitation(
            item = null,
            space = null,
            board = null,
        ) {
            const url = this.prepareUrlForItemInvitation(item, space, board);
            return `${url}?conversation=true`;
        },
        prepareUrlForItemInvitationByItemId(item_id, space_id, board_id) {
            return `/workspaces/${space_id}/boards/${board_id}/items/${item_id}/invited-members`;
        },
        prepareUrlForItemAssigned(item = null, space = null, board = null) {
            const url = this.prepareUrlForItem(item, space, board);
            return `${url}/assign`;
        },
        prepareUrlForItemDocument(item = null, space = null, board = null) {
            const url = this.prepareUrlForItem(item, space, board);
            return `${url}/document`;
        },

        prepareUrlForItemId(item_id) {
            const item_data = this.getSpaceBoardForItem(item_id);
            return this.prepareUrlForItem(
                item_data.item?.slug,
                item_data.space.slug,
                item_data.board?.slug,
            );
        },
        getSpaceCategoryAndIndexForSpace(space) {
            if (!space) return { category: null, index: -1 };

            const category = this.space_categories.find(
                (category) => category?.id == space?.workspace_category_id,
            );

            const index = category?.workspaces?.findIndex(
                (ws) => ws.id == space.id,
            );

            return {
                category,
                index: index == null || index < 0 ? -1 : index,
            };
        },

        getBoardCategoryAndIndexForBoardInSpace(board, space) {
            if (!board) return { category: null, index: -1 };

            const category = space?.board_categories.find(
                (category) => category.id == board.board_category_id,
            );

            const index = category?.boards?.findIndex((b) => b.id == board.id);

            return {
                category,
                index: index == null || index < 0 ? -1 : index,
            };
        },

        getSourceAndDestinationItemCategoriesForItem(newItem) {
            const item_info = this.getOrLoadSpaceAndBoardForItemId(newItem.id);

            const source_and_destination = {
                source: null,
                destination: null,
            };

            this.space_categories.find((sc) => {
                return sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find((bc) => {
                        return bc.boards?.find((board) => {
                            return board.item_categories?.find((ic) => {
                                if (ic.id == newItem.item_category_id) {
                                    source_and_destination.destination = ic;
                                }

                                let matched_item = ic.items.find(
                                    (item) => item.id == newItem.id,
                                );
                                if (matched_item)
                                    source_and_destination.source = ic;

                                return (
                                    source_and_destination.destination &&
                                    source_and_destination.source
                                );
                            });
                        });
                    });
                });
            });
            return source_and_destination;
        },

        getSourceAndDestinationBoardByItemGroup(newItemGroup) {
            // const item_info = this.getOrLoadSpaceAndBoardForItemId(newItemGroup.id)
            const source_and_destination = {
                source: null,
                destination: null,
            };

            this.space_categories.find((sc) => {
                return sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find((bc) => {
                        return bc.boards?.find((board) => {
                            if (board.id == newItemGroup?.board_id) {
                                source_and_destination.destination = board;
                            }

                            let item_group_found = board?.item_categories?.find(
                                (item_category) =>
                                    item_category.id == newItemGroup.id,
                            );
                            if (item_group_found)
                                source_and_destination.source = board;

                            return (
                                source_and_destination.destination &&
                                source_and_destination.source
                            );
                        });
                    });
                });
            });
            return source_and_destination;
        },
        getSourceAndDestinationBoardCategoriesForBoard(newBoard) {
            const board = this.getBoardByBoardId(newBoard.id);

            const source_and_destination = {
                source: null,
                destination: null,
            };

            this.space_categories.find((sc) => {
                return sc.workspaces?.find((workspace) => {
                    return workspace.board_categories?.find((bc) => {
                        if (bc.id == newBoard.board_category_id) {
                            source_and_destination.destination = bc;
                        }

                        let matched_board = bc.boards.find(
                            (board) => board.id == newBoard.id,
                        );
                        if (matched_board) source_and_destination.source = bc;

                        return (
                            source_and_destination.destination &&
                            source_and_destination.source
                        );
                    });
                });
            });
            return source_and_destination;
        },
        getSourceAndDestinationSpaceCategoriesForSpace(newSpace) {
            const space = this.getSpaceBySpaceId(newSpace.id);

            const source_and_destination = {
                source: null,
                destination: null,
            };

            this.space_categories.find((sc) => {
                if (sc.id == newSpace.workspace_category_id) {
                    source_and_destination.destination = sc;
                }

                let matched_space = sc.workspaces.find(
                    (workspace) => workspace.id == newSpace.id,
                );
                if (matched_space) source_and_destination.source = sc;

                return (
                    source_and_destination.destination &&
                    source_and_destination.source
                );
            });
            return source_and_destination;
        },
        async loadSpaceAndBoardsForItemIds(item_ids) {
            do {
                item_ids =
                    this.filterOutNotFoundItemIdsFromLoadedWorkspaces(item_ids);
                const is_found = await this.loadSpaceBoardAndItemsForItem(
                    item_ids[0],
                );
                //console.log("is_found : ", is_found, item_ids[0], this.findItemInWorkspaces({id: item_ids[0]}));
                item_ids.shift();
            } while (item_ids.length);
        },

        filterOutNotFoundItemIdsFromLoadedWorkspaces(item_ids) {
            let not_found_ids = item_ids;

            this.space_categories.forEach((sc) => {
                sc.workspaces?.forEach((workspace) => {
                    workspace.board_categories?.forEach((bc) =>
                        bc.boards?.forEach((board) =>
                            board.item_categories?.forEach((ic) =>
                                ic.items?.forEach(
                                    (item) =>
                                        (not_found_ids = not_found_ids.filter(
                                            (id) => id != item.id,
                                        )),
                                ),
                            ),
                        ),
                    );
                });
            });
            return not_found_ids;
        },
        async getOrLoadSpaceAndBoardForItemId(item_id) {
            let itemInfo = this.getSpaceBoardForItem(item_id);

            if (itemInfo?.item) return itemInfo;

            await this.loadSpaceBoardAndItemsForItem(item_id);
            return this.getSpaceBoardForItem(item_id);
        },
        async loadSpaceBoardAndItemsForItem(item_id) {
            if (!item_id) return false; //console.log("can't load space board and items for item with null id ")

            let data = {};
            try {
                const response = await axios.get(
                    `/load-space-board?item=${item_id}`,
                );
                data = response.data;
            } catch (e) {}

            if (!data.workspace || !data.board) return false;

            const workspace_group = data.workspace_categories;

            const workspace_data = this.getSpaceCategoryAndIndexForSpace(
                data.workspace,
            );

            if (workspace_group && workspace_data.index == -1) {
                workspace_group.workspaces = [];
                workspace_group.workspaces.push(data.workspace);
            }

            const space_category = this.space_categories.find(
                (category) => category.id == data.workspace_category_id,
            );
            if (!space_category) {
                this.space_categories.push(workspace_group);
            }

            let workspace = data.workspace;
            const { category, index } =
                this.getSpaceCategoryAndIndexForSpace(workspace);

            if (index == -1) category?.workspaces?.push(workspace);
            else {
                const board_categories = workspace.board_categories;
                workspace = category.workspaces[index];
                if (!workspace.board_categories)
                    workspace.board_categories = board_categories;

                //console.log('current-workspace: ', workspace.name)
            }

            const board = data.board;
            const { category: board_category, index: board_index } =
                this.getBoardCategoryAndIndexForBoardInSpace(board, workspace);

            if (board_index == -1) {
                board_category?.boards?.push(board);
            } else {
                const current_board = board_category?.boards[board_index];
                current_board.item_categories = board?.item_categories;
                //console.log('current-board: ', current_board.name)
            }

            return true;
        },

        async inviteUsersToSpace(invited_profile_ids) {
            this.is_invitation_in_progress = true;
            const space = this.invited_data;
            const data = {
                invited_profile_ids: invited_profile_ids,
            };
            const url = useWorkspace().prepareUrlWithSpace(space);
            return axios
                .post(`${url}/invite`, data)
                .then(({ data }) => {
                    useAppState().toggleInviteDrawer(false);

                    Utilities().toggleSnackBar({
                        message: `${invited_profile_ids.length} new memebers have been invited on ${space.name}`,
                        timeout: 3000,
                        color: "success",
                        location: "bottom right",
                    });

                    this.invited_data = null;
                    this.invite_type = null;
                    this.is_invitation_in_progress = false;
                    return data;
                })
                .catch((error) => {
                    this.is_invitation_in_progress = false;
                });
        },

        async inviteUsersToBoard(invited_profile_ids) {
            this.is_invitation_in_progress = true;
            const board = this.active_chat_data;
            const data = {
                invited_profile_ids: invited_profile_ids,
            };
            const url = useWorkspace().prepareUrlWithSpace(this.active_space);
            return axios
                .post(this.prepareUrlForBoardInvitation(board), data)
                .then(({ data }) => {
                    useAppState().toggleInviteDrawer(false);

                    Utilities().toggleSnackBar({
                        message: `${invited_profile_ids.length} new memebers have been invited on ${board.name}`,
                        timeout: 3000,
                        color: "success",
                        location: "bottom right",
                    });

                    const convData = {
                        name: board.name,
                        type: "board",
                        type_id: board.id,
                        participants: invited_profile_ids,
                    };
                    useConversation().createFakeConversastion(convData);
                    this.invite_type = null;
                    this.invited_data = null;
                    this.is_invitation_in_progress = false;
                    return data;
                })
                .catch((error) => {
                    this.is_invitation_in_progress = false;
                });
        },

        async inviteUsersToSpaceGroup(invited_profile_ids) {
            this.is_invitation_in_progress = true;
            const space_group = this.invited_data;
            const data = {
                invited_profile_ids: invited_profile_ids,
            };
            return axios
                .post(this.prepareUrlFoSpaceGroupInvitation(space_group), data)
                .then(({ data }) => {
                    useAppState().toggleInviteDrawer(false);

                    Utilities().toggleSnackBar({
                        message: `${invited_profile_ids.length} new memebers have been invited on ${space_group.name}`,
                        timeout: 3000,
                        color: "success",
                        location: "bottom right",
                    });

                    this.invite_type = null;
                    this.invited_data = null;
                    this.is_invitation_in_progress = false;
                    return data;
                })
                .catch((error) => {
                    this.is_invitation_in_progress = false;
                });
        },

        async inviteUsersToBoardGroup(invited_profile_ids) {
            this.is_invitation_in_progress = true;
            const board_group = this.active_chat_data;
            const data = {
                invited_profile_ids: invited_profile_ids,
            };
            return axios
                .post(this.prepareUrlForBoardGroupInvitation(board_group), data)
                .then(({ data }) => {
                    useAppState().toggleInviteDrawer(false);

                    Utilities().toggleSnackBar({
                        message: `${invited_profile_ids.length} new memebers have been invited on ${board_group.name}`,
                        timeout: 3000,
                        color: "success",
                        location: "bottom right",
                    });

                    const convData = {
                        name: board_group.name,
                        type: "board-group",
                        type_id: board_group.id,
                        participants: invited_profile_ids,
                    };
                    useConversation().createFakeConversastion(convData);
                    this.invite_type = null;
                    this.is_invitation_in_progress = false;
                    return data;
                })
                .catch((error) => {
                    this.is_invitation_in_progress = false;
                });
        },
        async inviteUsersToItemGroup(invited_profile_ids) {
            this.is_invitation_in_progress = true;
            const item_group = this.active_chat_data;

            const data = {
                invited_profile_ids: invited_profile_ids,
            };

            const url = this.prepareUrlWithSpaceAndBoard(
                this.active_space,
                this.active_board,
            );

            return axios
                .post(`${url}/item-categories/${item_group.id}/invite`, data)
                .then(({ data }) => {
                    useAppState().toggleInviteDrawer(false);

                    Utilities().toggleSnackBar({
                        message: `${invited_profile_ids.length} new memebers have been invited on ${item_group.name}`,
                        // message: `You have successfully invited ${item_group.name} on item group!`,
                        timeout: 3000,
                        color: "success",
                        location: "bottom right",
                    });

                    const convData = {
                        name: item_group.name,
                        type: "item-group",
                        type_id: item_group.id,
                        participants: invited_profile_ids,
                    };

                    useConversation().createFakeConversastion(convData);
                    this.invite_type = null;
                    this.is_invitation_in_progress = false;
                    return data;
                })
                .catch((error) => {
                    this.is_invitation_in_progress = false;
                });
        },
        async inviteUsersToItem(profile_ids) {
            this.is_invitation_in_progress = true;
            const item = this.active_chat_data;
            const data = {
                profile_ids: profile_ids,
            };

            const conversation = useConversation().getForItemId(item.id);

            const new_profile_ids =
                conversation?.filterNewlyInvitedProfilesFromList(profile_ids);
            const deleted_profile_ids =
                conversation?.filterRemovedProfilesFromList(profile_ids);

            const item_board_space = this.getSpaceBoardForItem(item.id);

            const url = this.prepareUrlWithSpaceAndBoard(
                item_board_space.space,
                item_board_space.board,
            );
            return axios
                .post(`${url}/items/${item.slug}/invite`, data)
                .then(({ data }) => {
                    useAppState().toggleInviteDrawer(false, item);
                    Utilities().toggleSnackBar({
                        message: `${new_profile_ids.length} new members have been invited on ${item.name}`,
                        // message: `You have successfully invited ${item_group.name} on item group!`,
                        timeout: 3000,
                        color: "success",
                        location: "bottom right",
                    });

                    conversation?.sendInvitationForProfiles(new_profile_ids);
                    conversation?.sendRemovedInvitationForProfiles(
                        deleted_profile_ids,
                    );
                    this.is_invitation_in_progress = false;
                    return data;
                })
                .catch((error) => {
                    this.is_invitation_in_progress = false;
                });
        },
        async assignUsersToItem(
            assigned_profile_id_new,
            assigned_profile_id_old,
        ) {
            if (!assigned_profile_id_new) return;

            const assigned_profile_new = useUserStore().profiles.find(
                (profile) => profile.id == assigned_profile_id_new,
            );
            const item = this.active_chat_data;
            let actions_status = "assign";
            const data = {
                user_profile_id: assigned_profile_id_new,
                action: actions_status,
            };
            const conversation = useConversation().getForItemId(item.id);
            const item_board_space = this.getSpaceBoardForItem(item.id);

            return axios
                .post(
                    this.prepareUrlForItemAssigned(
                        item,
                        item_board_space.space,
                        item_board_space.board,
                    ),
                    data,
                )
                .then(({ data }) => {
                    useTeamPanel().addItemToTaskGroup(
                        assigned_profile_id_new,
                        item,
                        data,
                    );
                    useTeam().setOriginalAssigned(assigned_profile_id_new);

                    conversation?.sendAssignedMessage(
                        assigned_profile_id_new,
                        assigned_profile_id_old,
                    );

                    Utilities().toggleSnackBar({
                        message: `${assigned_profile_new?.email} user profile have been ${actions_status} on ${item.name}`,
                        // message: `You have successfully invited ${item_group.name} on item group!`,
                        timeout: 3000,
                        color: "success",
                        location: "bottom right",
                    });
                    return data;
                });
        },
        addSpaceInCategory(space) {
            const index = this.space_categories.findIndex(
                (ws) => ws.id == space.workspace_category_id,
            );
            this.space_categories[index].workspaces.push(space);
        },

        async moveItems(
            items,
            before_id,
            selected_category,
            order = 0,
            selected_space = this.active_space,
            selected_board = this.active_board,
        ) {
            const url = this.prepareUrlWithSpaceAndBoard(
                selected_space,
                selected_board,
            );

            const ids = [];
            items.forEach((item) => ids.push(item.id));

            return axios.patch(`${url}/items/move`, {
                ids: ids,
                before_id: before_id,
                category_id: selected_category.id,
                order: order,
            });
        },

        moveTasks(items, selected_category, order = 0, before_id) {
            let ids = [];
            items.forEach((item) => ids.push(item.id));

            return axios.post(`/user-profile-tasks/move`, {
                item_ids: ids,
                task_group_id: selected_category.id,
                order: order,
                before_id: before_id,
            });
        },

        isCategoryAutoEndAssignAndItemHasAssignee(category, item) {
            return (
                category.automatic_end_assignment &&
                item?.version?.user_profile_id_assignee
            );
        },

        isResourceDeletable(type) {
            return !["feedback"].includes(type);
        },
        isResourceEditable(type) {
            return !["feedback"].includes(type);
        },
        isItemTrackable(item) {
            return (
                item?.version?.user_profile_id_assignee ===
                useUserStore().profile?.id
            );
        },
        updateGroupForItems(category, items = []) {
            let cat = this.active_board.item_categories.find(
                (cat) => cat.id === category.id,
            );

            items.forEach((item) => (item.item_category_id = category.id));

            if (cat?.items) cat.items = items;
        },
        updateGroup(group, index) {
            this.active_board.item_categories.splice(index, 1, group);
        },
        removeDuplicateItemGroup() {
            const unique_item_group_ids = new Set();
            this.active_board.item_categories =
                this.active_board.item_categories.filter((item_group) => {
                    if (!item_group?.id) return false;

                    if (!unique_item_group_ids.has(item_group?.id)) {
                        unique_item_group_ids.add(item_group.id);
                        return true;
                    }

                    return false;
                });
        },
        generateItemLink(item) {
            return this.generateLink({
                object_id: item.id,
                object_type: "item",
            });
        },
        async generateLink(payload) {
            return await axios.post(`/links`, payload);
        },

        setEditable(val) {
            this.is_editing_item = val;
        },

        setDisabledHoverMenu() {
            this.is_hover_menu_disabled = true;
        },

        setEnabledHoverMenu() {
            this.is_hover_menu_disabled = false;
        },

        async setActiveItemById(item_id, is_set_selected_item = true) {
            const res = await this.getOrLoadSpaceAndBoardForItemId(item_id);
            this.setActiveWorkspace(res.space);
            this.setActiveBoard(res.board);
            this.setActiveItem(res.item, is_set_selected_item);

            return res;
        },

        setLastUpdatedItem(val) {
            this.last_updated_item = val;
        },

        setChinItemProfileIds(selected_profile_ids, all_profile_ids) {
            this.chin.item_profile_ids = all_profile_ids;
            this.chin_selected.item_profile_ids = selected_profile_ids;
        },
        setChinBoardProfileIds(selected_profile_ids, all_profile_ids) {
            this.chin.board_profile_ids = all_profile_ids;
            this.chin_selected.board_profile_ids = selected_profile_ids;
        },
        setChinSpaceProfileIds(selected_profile_ids, all_profile_ids) {
            this.chin.space_profile_ids = all_profile_ids;
            this.chin_selected.space_profile_ids = selected_profile_ids;
        },
        setIsFetchingItem(val) {
            this.fetching_item = val;
        },

        setWorkspacesForWorkSpaceGroup(workspace_group_id, workspaces) {
            const workspace_group =
                this.getSpaceGroupBySpaceGroupId(workspace_group_id);

            if (workspace_group) {
                workspace_group.workspaces = workspaces;
                // this.updateNodeKey(workspace_group);
            }
        },

        setBoardsForBoardGroup(board_group_id, boards) {
            const board_group =
                this.getBoardGroupByBoardGroupId(board_group_id);

            if (board_group) {
                board_group.boards = boards;
                this.updateNodeKey(board_group);
            }
        },

        updateNodeKey(object) {
            if (!object) return;

            object.liveSyncKey = Date.now() + (object.id ?? 10);
        },
    },
});
